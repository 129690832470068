import React from 'react';
import './App.css';
import hyperSolverLogo from './Hypersolver3.jpg';
import moetsiOakDLogo from './moetsi-oakd-logo.png';
import metavaiLogo from './logo-metavai.png';
import metavaiServices from './metavai-services.png';

function App() {
  return (
    <div className="app-container">
      <h1 className="company-name"><img src={metavaiLogo} alt="Meta V AI Logo" className="logo-small"/> Meta V AI</h1>
      <h2 className="company-description">{"A Quantum of Exotic Hardware"}</h2>
      <div className="partners-container">
        <div className="partner">
          <img src={metavaiServices} alt="services" className="partner-logo-big" />
          <h3 className="partner-name"></h3>
        </div>
      </div>
      <p className="github-link">GitHub: <a href="https://github.com/MetaVai">https://github.com/MetaVai</a></p>
      <p className="footer">© Meta V AI Limited | ALL RIGHTS RESERVED | contact _at_ metavai.io</p>
    </div>
  );
}

export default App;
